<template>
  <div>
    <mobile v-if="isMobile"/>
    <pc v-else/>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Pc from './pc'
import Mobile from './mobile'

export default defineComponent({
  name: 'home-index',
  components: {
    Pc, Mobile
  },
  setup() {
    const isMobile = ref(false);
    const userAgent = window.navigator.userAgent
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      // 移动端设备
      isMobile.value = true;
    } else {
      // PC端设备
      isMobile.value = false;
    }

    return {
      isMobile,
    };
  },
});
</script>

<style scoped>

</style>
