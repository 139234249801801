<template>
  <div class="container">
    <div class="top-box">
      <div class="logo-box">
        <img src="../assets/pc/top-bg.png" art="背景图" class="top-bg wow animate__animated animate__fadeIn"
             data-wow-duration="3s"/>
        <div class="sun-box">
          <div class="sun">
            <img src="../assets/pc/light.png" class="light">
            <img src="../assets/pc/face.png" class="face">
          </div>
        </div>
      </div>
      <div ref="target" class="slogan-box">
        <img src="../assets/pc/slogan-yellow.png" art="花蹊标语" v-for="(item, index) in 17" :key="index"
             :class="{'slogan':true} " :style="{bottom: isInView ? index*20 + 'px' : 0}"/>
        <img src="../assets/pc/slogan-white.png" art="花蹊标语" class="slogan"
             :style="{bottom: isInView ? 16*20 + 'px' : 0}"/>
      </div>
      <div class="class-info">
        <img src="../assets/pc/class1.png" art="展览班" class="class1 wow animate__animated animate__flipInY"/>
        <img src="../assets/pc/class2.png" art="共探班" class="class1 wow animate__animated animate__flipInY"
             data-wow-delay=".8s"/>
        <img src="../assets/pc/class3.png" art="专业班" class="class1 wow animate__animated animate__flipInY"
             data-wow-delay="1.6s"/>
        <img src="../assets/pc/class4.png" art="创作班" class="class1 wow animate__animated animate__flipInY"
             data-wow-delay="2.4s"/>
      </div>
      <img src="../assets/pc/works-1.png" art="部分学生作品" class="works-1 wow animate__animated animate__fadeInDown"/>
    </div>
    <div class="background"></div>
    <img src="../assets/pc/student-works.png" art="部分学生作品"
         class="student-works wow animate__animated animate__zoomIn"/>
    <img src="../assets/pc/works-2.png" art="部分老师作品" class="works-2"/>
    <div class="teacher-works-box">
      <img src="../assets/pc/teacher-works.png" art="部分老师作品"
           class="teacher-works wow animate__animated animate__zoomIn"/>
    </div>
    <div class="footer wow animate__animated animate__slideInUp">
      <div class="footer-msg">
        <div class="left">
          <div class="art">HUAXI·ART</div>
          <div class="title">让孩子能画画、爱画画、擅长画画</div>
          <div class="msg">真正的美术教育，重点是在于观察、发现以及表达方法， 而不是美，更不是创造力。美是孩子们内心所感受的，
            这和他的生活、见识有很大的关系。而老师是无法引导的，老师引导的美、创造力都是带有自己的审美标准和知识天花板的
          </div>
          <div class="msg">
            所以，美术教育是教会孩子们表达方法、观察方法、绘画技法，从而让孩子们有能力随心画出自己想表达的人、事、物、景、情等。
          </div>
          <div class="msg">就想毕加索说的：我们不是创造，也不是去探索，而是去发现...</div>
          <div class="msg address">校区地址：正商航海广场校区 天旺广场校区 亚太时代广场校区...</div>
        </div>
        <div class="right">
          <img src="../assets/pc/sunflower.png" art="花蹊标志" class="sun-flower"/>
          <div>16696661124 (乔老师)</div>
          <div>18539251626 (苏老师)</div>
        </div>
      </div>
      <div class="beian">工信部备案：<a href="https://beian.miit.gov.cn/" target="_blank"> 豫ICP备2023023142号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'

export default {
  name: "pc-index",
  data() {
    return {
      isInView: false,
    }
  },

  mounted() {
    new WOW().init()
    this.$nextTick(() => {
      window.addEventListener('scroll', this.checkIfInView);
    });
  },
  methods: {
    checkIfInView() {
      const target = this.$refs.target;
      if (!target) return
      const rect = target.getBoundingClientRect();
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
      const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
      if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth) {
        this.isInView = true;
      }
      if (rect.bottom < 0) {
        this.isInView = false
      }
    },
  },

}
</script>

<style scoped>
.sun-box {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun {
  width: 16%;
  position: relative;
}

.logo-box {
  position: relative;
}

.light {
  width: 100%;
  animation: rotate 10s linear infinite normal;
  transform-origin: center;
}

@keyframes rotate {

  0% {

    transform: rotate(0deg);
  }

  100% {

    transform: rotate(360deg);
  }
}

.face {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.slogan-box {
  width: 1305px;
  height: 600px;
  margin: 110px auto 140px;
  position: relative;
}

.slogan {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: bottom 1s;
}

.container {
  min-width: 1320px;
}

.background {
  width: 100%;
  height: 400px;
  background-color: #ffffff;
  position: relative;
  font-size: 0;
  overflow: hidden;
}

.background:before, .background:after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 800px;
  border-radius: 50%;
}

.background:before {
  left: 0;
  background: #e8490f;
}

.background:after {
  right: 0;
  background-color: #e8490f;
}

.top-box {
  background-color: #e8490f;
  font-size: 0;
  position: relative;
  padding-bottom: 1px;
}

.top-bg {
  width: 100%;
}

.slogan {
  /*width: 64%;*/
  /*max-width: 1222px;*/
  /*margin: 140px auto 160px;*/
  /*background: plum;*/
}

.class-info {
  width: 73%;
  max-width: 1400px;
  margin: 10px auto 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.class1 {
  display: block;
  width: 100%;
}

.works-1 {
  width: 20%;
  /*max-width: 368px;*/
  /*margin: 20px auto -30px;*/
  position: absolute;
  left: 40%;
  bottom: -250px;
  z-index: 999;
}

.student-works {
  width: 83%;
  max-width: 1586px;
  margin: 50px auto 20px;
}

.works-2 {
  width: 20%;
  max-width: 368px;
  margin: 140px auto 120px;
}

.teacher-works-box {
  background: #fef6e9;
  padding: 80px 0;
}

.teacher-works {
  width: 80%;
  max-width: 1555px;
  margin: 0 auto;
}

.footer {
  background: #f98f24;
  padding: 100px 10% 15px;
}

.footer-msg {
  display: flex;
  justify-content: space-between;
}

.left {
  margin-left: 20px;
  flex: 1;
}

.art {
  font-size: 46px;
  letter-spacing: 8px;
}

.title {
  font-size: 46px;
  margin: 20px 0 50px 0;
  letter-spacing: 2px;
}

.msg {
  font-size: 20px;
  letter-spacing: 2px;
  text-align: justify;
  width: 712px;
}

.address {
  margin-top: 70px;
}

.right {
  font-size: 28px;
  letter-spacing: 2px;
}

.sun-flower {
  width: 280px;
  margin: 70px auto 90px;
}

.beian {
  font-size: 14px;
  text-align: center;
  margin-top: 100px;
}

.beian a {
  color: #ffffff;
}
</style>
