<template>
  <div>
    <div class="top-box wow animate__animated animate__fadeInDown">
      <img src="../assets/mobile/top-bg.png" art="背景图"/>
      <img src="../assets/mobile/logo.png" art="花蹊标志" class="logo"/>
    </div>
    <img src="../assets/mobile/slogan.png" art="花蹊标语" class="slogan wow animate__animated animate__zoomIn"
         data-wow-delay="1s"/>
    <div class="wow animate__animated animate__fadeInUp">
      <img src="../assets/mobile/arc-top.png" art="背景图"/>
      <div class="class-info">
        <div ref="target" class="slogan-box">
          <img src="../assets/mobile/slogan-yellow.png" art="花蹊标语" v-for="(item, index) in 17" :key="index"
               :class="{'slo':true} " :style="{bottom: isInView ? index*.3 + 'rem' : 0}"/>
          <img src="../assets/mobile/slogan-white.png" art="花蹊标语" class="slo"
               :style="{bottom: isInView ? 16*.3 + 'rem' : 0}"/>
        </div>

        <img src="../assets/mobile/class01.png" art="共探班" class="class"/>
        <img src="../assets/mobile/class02.png" art="专业班" class="class wow animate__animated animate__flipInY"/>
        <img src="../assets/mobile/class03.png" art="创作班" class="class wow animate__animated animate__flipInY"/>
        <img src="../assets/mobile/class04.png" art="展览班" class="class wow animate__animated animate__flipInY"/>
        <img src="../assets/mobile/works-1.png" art="部分学生作品" class="works-1"/>
      </div>
      <img src="../assets/mobile/arc-bottom.png" art="背景图"/>
    </div>

    <img src="../assets/mobile/student-works.png" art="部分学生作品"
         class="student-works wow animate__animated animate__zoomIn"/>
    <img src="../assets/mobile/works-2.png" art="部分老师作品" class="works-2"/>
    <img src="../assets/mobile/teacher-works.png" art="部分老师作品"
         class="teacher-works wow animate__animated animate__zoomIn"/>
    <div class="footer wow animate__animated animate__slideInUp">
      <img src="../assets/mobile/sunflower.png" art="向日葵" class="sun-flower"/>
      <div class="btn" @click="showCode = !showCode">报名咨询</div>

      <div v-if="showCode" class="code-box wow animate__animated animate__zoomIn">
        <div class="tip">微信扫码咨询</div>
        <div class="bottom-box">
          <img src="../assets/mobile/code.png" alt="二维码" class="code"/>
          <div class="mobile">咨询电话：16696661124（乔老师）</div>
        </div>
      </div>

      <div class="title">让孩子能画画、爱画画、擅长画画</div>
      <div class="msg">真正的美术教育，重点是在于观察、发现以及表达方法， 而不是美，更不是创造力。美是孩子们内心所感受的，
        这和他的生活、见识有很大的关系。而老师是无法引导的，老师引导的美、创造力都是带有自己的审美标准和知识天花板的
      </div>
      <div class="msg">
        所以，美术教育是教会孩子们表达方法、观察方法、绘画技法，从而让孩子们有能力随心画出自己想表达的人、事、物、景、情等。
      </div>
      <div class="msg">就想毕加索说的：我们不是创造，也不是去探索，而是去发现...</div>
      <div class="msg address">校区地址：正商航海广场校区 天旺广场校区 亚太时代广场校区...</div>
      <div class="name">花蹊美术</div>
      <div class="beian">工信部备案：<a href="https://beian.miit.gov.cn/" target="_blank"> 豫ICP备2023023142号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'

export default {
  name: "mobile-index",
  data() {
    return {
      isInView: false,
      showCode: false
    }
  },

  mounted() {
    new WOW().init()
    this.$nextTick(() => {
      window.addEventListener('scroll', this.checkIfInView);
    });
    setTimeout(() => {
      this.isInView = true;
    }, 1000)

  },
  methods: {
    checkIfInView() {
      const target = this.$refs.target;
      if (!target) return
      const rect = target.getBoundingClientRect();
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
      const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
      if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth) {
        this.isInView = true;
      }
      if (rect.bottom < 0) {
        this.isInView = false
      }
    },
  },

}
</script>

<style scoped>
.slogan-box {
  width: 70%;
  height: 10rem;
  margin: 0 auto 3rem;
  position: relative;
}

.slo {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: bottom 1s;
}

.top-box {
  position: relative;
}

.logo {
  width: 46%;
  position: absolute;
  left: 27%;
  top: 7rem;
}

.slogan {
  width: 64%;
  margin: 2rem 18%;
}

.class-info {
  background-color: #e8490f;
  position: relative;
  padding-bottom: 6rem;
}

.watch-word {
  width: 64%;
  margin: 0 18%;
  padding: 1.5rem 0 3rem 0;
}

.class {
  width: 72%;
  margin: .5rem 0 .5rem 11%;
}

.works-1 {
  width: 30%;
  position: absolute;
  left: 35%;
  bottom: -1rem;
}

.student-works {
  width: 86%;
  margin: 1rem 7%;
}

.works-2 {
  width: 28%;
  margin: 3.3rem 36%;
}

.footer {
  background-color: #e8490f;
}

.sun-flower {
  width: 30%;
  margin: 0 35%;
  padding: 3rem 0;
}

.btn {
  width: 76%;
  margin: 0 12%;
  text-align: center;
  padding: .6rem;
  color: #e8490f;
  font-size: 1.2rem;
  background-color: #ffffff;
  border: .3rem solid #f06d3d;
  border-radius: 100px;
}

.title {
  width: 76%;
  margin: 3.3rem 12% 1rem 12%;
  font-size: 1.1rem;
  text-align: center;
}

.msg {
  width: 76%;
  margin: 0 12%;
  color: #fef6e9;
  text-align: justify;
  font-size: .9rem;
}

.address {
  margin-top: 2.2rem;
}

.name {
  color: #ffffff;
  font-size: 1rem;
  padding: 3.6rem 0 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.name:after, .name:before {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: .0625rem solid #ffffff;
  content: "";
  margin-top: .0625rem;
}

.name:after {
  margin-left: 1.5rem;
}

.name:before {
  margin-right: 1.5rem;

}

.code-box {
  width: 76%;
  margin: 1rem 12%;
}

.tip {
  text-align: center;
  font-size: 1.3rem;
  padding: .6rem 0;
  background-color: #ff6b28;
  border: .5rem solid #ff5520;
  border-bottom: none;
  border-radius: .8rem .8rem 0 0;
}

.bottom-box {
  background-color: #ff9538;
  border: .5rem solid #ff772d;
  border-top: none;
  border-radius: 0 0 0.8rem .8rem;
  padding: 1.5rem 0 1rem;
}

.code {
  width: 60%;
  margin: 0 auto;
  border: .5rem solid #fce3c0;
  border-radius: .6rem;
}

.mobile {
  font-size: .8rem;
  text-align: center;
  margin-top: 1.5rem;
}

.beian {
  font-size: .7rem;
  text-align: center;
  padding-bottom: .8rem;
}

.beian a {
  color: #ffffff;
}
</style>
